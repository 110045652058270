import tw from 'twin.macro'
import { graphql, PageProps } from 'gatsby'

import Seo from '@components/Seo'
import PageCover from '@components/PageCover'
import Layout from '@partials/Layout'
import { GatsbyImage } from 'gatsby-plugin-image'
import HeaderSlider from '@components/HeaderSlider'

import { formatExcerpt } from '@utils/functions'

import { WpPostConnection, WpPage } from '@_types/graphql-types'
import Title from '@components/Title'
import MiniPost from '@components/MiniPost'
import Section from '@components/Section'
import Button from '@components/Button'
import Pagination from '@components/Pagination'
import PaginatedBlog from '@components/PaginatedBlog'

type BlogProps = {
  posts: WpPostConnection
  pesquisa: WpPostConnection
  equidade: WpPostConnection
  formacao: WpPostConnection
  mostViewed: WpPostConnection
  pageData: WpPage
}

type ContextType = {
  pageNumber: number
}

const Blog = ({
  data: { posts, pesquisa, equidade, formacao, pageData },
  pageContext
}: PageProps<BlogProps, ContextType>) => {
  if (pageContext.pageNumber !== 0) {
    return <PaginatedBlog posts={posts} seo={pageData} context={pageContext} />
  }

  const pageCoverImage = pageData.featuredImage?.node ? (
    <GatsbyImage
      image={pageData.featuredImage.node.localFile.childImageSharp['full']}
      alt={pageData.featuredImage.node.altText}
      loading="eager"
      objectFit="cover"
      tw="z-0 left-0 top-0 w-full h-full pointer-events-none"
    />
  ) : (
    <div></div>
  )

  return (
    <Layout
      headerCover={
        <PageCover
          type="header"
          title={pageData.title}
          image={pageCoverImage}
        />
      }
    >
      <Seo
        title={pageData.seo.title}
        description={pageData.seo.metaDesc}
        canonical={pageData.seo.canonical}
        images={pageData.seo.opengraphImage}
        modified={pageData.seo.opengraphModifiedTime}
        date={pageData.seo.opengraphPublishedTime}
        type="page"
      />
      <Section>
        <div className="container" tw="px-4 py-24">
          <div tw="mb-12">
            <Title center title="EM PAUTA" tag="h2" color="dark" />
          </div>

          <div tw="xl:w-4/5 mx-auto">
            <div>
              {posts.edges[0] && (
                <MiniPost
                  colorScheme="dark"
                  featured
                  post={posts.edges[0].node}
                  layout="vertical"
                />
              )}
            </div>
            <div>
              {posts.edges.slice(1).map(({ node }) => (
                <MiniPost
                  key={node.id}
                  colorScheme="dark"
                  post={node}
                  layout="horizontal"
                />
              ))}
            </div>
          </div>

          <Pagination pageContext={pageContext} pathPrefix="/nos-acompanhe" />
        </div>
      </Section>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query GET_BLOG($limit: Int!, $skip: Int!) {
    pageData: wpPage(isPostsPage: { eq: true }) {
      title
      seo {
        ...SEO
      }
      featuredImage {
        node {
          ...FeaturedImageFields
        }
      }
    }
    posts: allWpPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostFields

          featuredImage {
            node {
              ...FeaturedImageFields
            }
          }
        }
      }
    }
    pesquisa: allWpPost(
      sort: { fields: date, order: DESC }
      limit: 4
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: "pesquisa" } } } }
      }
    ) {
      edges {
        node {
          ...PostFields

          featuredImage {
            node {
              ...FeaturedImageFields
            }
          }
        }
      }
    }

    formacao: allWpPost(
      sort: { fields: date, order: DESC }
      limit: 2
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: "formacao" } } } }
      }
    ) {
      edges {
        node {
          ...PostFields

          featuredImage {
            node {
              ...FeaturedImageFields
            }
          }
        }
      }
    }
    equidade: allWpPost(
      sort: { fields: date, order: DESC }
      limit: 4
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: "equidade" } } } }
      }
    ) {
      edges {
        node {
          ...PostFields

          featuredImage {
            node {
              ...FeaturedImageFields
            }
          }
        }
      }
    }
  }
`
